@import "../vars";

.root {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;

  svg {
    width: 150px;
    display: block;
    padding: 20px;
    margin: 0 auto;

    polygon {
      animation-name: pulsePolygon;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    @for $i from 1 through 84 {
      polygon:nth-of-type(#{$i}) {
        animation-delay: #{$i * random(1) * 0.1}s;
      }
    }
  }
}

.inner {
  background: #2a1cb3;
  padding: 10px;
  text-transform: uppercase;
  border: 5px solid $gold;
  text-align: center;

  span {
    animation: 1s blink infinite;
  }
}

.text {
  color: $yellow;
}

@keyframes pulsePolygon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
