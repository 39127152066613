@import "vars";

.root {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: fixed;
  text-align: left;
  top: 0px;
  width: 100%;
  z-index: 100;

  a {
    color: inherit;
  }
}

.bg {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(222, 235, 225, 1) 0%,
    rgba(222, 235, 225, 0) 100%
  );
  height: 100px;
  top: 0;
}

.logo {
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 395px;
  height: 40px;
  background-image: url("./img/2021/header-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;

  @media (max-width: 580px) {
    background-image: url("./img/2021/header-logo-narrow.png");
    width: 244px;
  }
}

.browseLogo {
  background-image: url("./img/2021/logo-text-only.png");
  width: 171px;
  height: 50px;
  transform: translateY(5px);

  @media (max-width: 580px) {
    background-image: url("./img/2021/logo-text-only.png");
  }
}

.links {
  display: flex;
  align-items: center;
}

.pinterest {
  font-size: 0.7em;
  margin-right: 10px;
  text-decoration: none;

  img {
    height: 30px;
    display: block;
    padding-top: 3px;

    @media (max-width: $mobile) {
      height: 25px;
    }
  }
}

.navLink {
  margin-right: 60px;
  margin-top: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;

  @media (max-width: 800px) {
    margin-right: 40px;
  }

  @media (max-width: 730px) {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 30px;
  }
}

.browse {
  background: $pink;
  color: #fff !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-family: $newFontBold;
  transition: 300ms all;

  &:hover {
    background: darken($pink, 10%);
  }

  @media (max-width: $mobile) {
    padding: 0px 15px !important;
  }
}
