$background: #000;
$outline: #eee;
$pink: #de01c7;
$gold: $pink;
$outlineFocus: #caa243;
$gray: #92a8bd;
$grey: #92a8bd;

$error: #ff7070;
$borderRadius: 10px;

$mobile: 769px;

$xl: 1300px;

$headerHeight: 50px;

$minHeight: calc(100vh - #{$headerHeight} - #{$headerHeight});
$borderH: 7vh;
$borderW: 7vw;

$borderHMobile: 2vh;
$borderWMobile: 5vw;

$bodyFont: "Europa Grotesk", monospace;
//$fancyFont: 'clarendon_btbold', sans-serif;
//$boldFont: 'clarendon_btbold', sans-serif;

$fancyFont: "Europa Grotesk", monospace;
$boldFont: "Europa Grotesk", monospace;

$newFontBold: "Europa Grotesk", monospace;
$newFont: "Europa Grotesk", monospace;

$font-color: #2a1cb3;
$yellow: #f7ee5e;
