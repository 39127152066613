@import "../vars.scss";

.root {
  height: 100vh;
  min-height: 850px;
  text-align: center;
  line-height: 1.5;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: bold;
}

.biglogo {
  max-width: 1300px;
  padding: 0 1vw;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.caps {
  text-transform: uppercase;
  font-size: 1.1em;
}

.buttons {
  margin-top: 20px;
}

.button {
  color: $yellow;
  text-decoration: none;
  background-image: url("../img/2021/button.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 103px;
  width: 303px;
  padding-top: 36px;
  padding-left: 6px;
  transition: background 0.5s;
  transition: color 0.5s;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: $pink;
  }
}
