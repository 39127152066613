.text {
  right: 5vw;
  width: 90vw;
  height: 40vh;
  background: url(../img/2021/reshape-possible.png) center center no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  text-align: center;
}
